@use 'utils' as util;

/* Alpha */
$alpha-100: 1;
$alpha-70: 0.7;
$alpha-30: 0.3;
$alpha-15: 0.15;

/* Theme colors */
$fresh-turquoise: #1dd3b0;
$vivid-green: #8bcd36;
$shadow-purple: #3c1642;
$lake-blue: #1677a0;

/* Utility colors */
$neutral-grey: #8d8d8d;
$danger-red: #dc4c64;
$warning-yellow: #e4a11b;
$info-teal: #54b4d3;
$success-green: #14a44d;

:root {
  // Theme colors
  --fresh-turquoise: #{util.to-rgb($fresh-turquoise)};
  --vivid-green: #{util.to-rgb($vivid-green)};
  --shadow-purple: #{util.to-rgb($shadow-purple)};
  --lake-blue: #{util.to-rgb($lake-blue)};

  // Utility colors
  --neutral-grey: #{util.to-rgb($neutral-grey)};
  --danger-red: #{util.to-rgb($danger-red)};
  --warning-yellow: #{util.to-rgb($warning-yellow)};
  --info-teal: #{util.to-rgb($info-teal)};
  --success-green: #{util.to-rgb($success-green)};
}
