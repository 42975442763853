@use 'breakpoints' as breakpoint;
@use 'variables' as var;

.fbw {
  .mdc-dialog {
    &__title {
      --mdc-dialog-subhead-font: #{var.$font-title};
      --mdc-dialog-subhead-color: rgb(var(--shadow-purple));
    }

    &__surface {
      --mdc-dialog-container-shape: 24px;

      padding: 1.5rem 1rem;
    }

    @include breakpoint.notMobile {
      &__surface {
        padding: 2rem 1.5rem;
      }
    }
  }
}
