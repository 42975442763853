@use 'breakpoints' as br;
@use 'colors' as color;

.fbw {
  button.mdc-button.mat-mdc-button-base,
  a.mdc-button.mat-mdc-button-base {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: initial;
    border-radius: 16px;
    padding: 0.75rem 1.25rem;
    height: 3rem;
    gap: 0.25rem;

    > .mat-icon {
      display: flex;
      width: 1.25rem;
      height: 1.25rem;
      margin: 0;
    }

    &[mat-flat-button] {
      &[color='primary'] {
        color: white;

        &:not([disabled]) {
          text-shadow: 0 0 0.5rem scale-color($color: color.$fresh-turquoise, $lightness: -25%);
        }
      }
    }

    &[mat-stroked-button] {
      --mat-stroked-button-color: var(--shadow-purple);

      color: rgb(var(--mat-stroked-button-color));
      border: 2px solid rgb(var(--mat-stroked-button-color));

      &[color='primary'] {
        --mat-stroked-button-color: var(--fresh-turquoise);
      }

      &[color='accent'] {
        --mat-stroked-button-color: var(--vivid-green);
      }
    }
  }

  button.mdc-fab.mat-mdc-button-base,
  a.mdc-fab.mat-mdc-button-base {
    --mdc-fab-container-shape: 20px;

    &.fbw-fab {
      &--large {
        --mdc-fab-container-shape: 24px;

        width: 4rem;
        height: 4rem;

        > .mat-icon {
          width: 2.25rem;
          height: 2.25rem;
        }
      }

      @include br.notMobile {
        &--large {
          --mdc-fab-container-shape: 32px;

          width: 6rem;
          height: 6rem;

          > .mat-icon {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }
    }

    &[color='accent'] {
      color: white;
    }
  }

  button.mdc-icon-button.mat-mdc-icon-button,
  a.mdc-icon-button.mat-mdc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    > .mat-mdc-button-touch-target {
      width: var(--mdc-icon-button-state-layer-size);
      height: var(--mdc-icon-button-state-layer-size);
    }

    &[mat-icon-button] {
      &.mat-icon-button-small {
        --mdc-icon-button-state-layer-size: 2rem;
      }
    }
  }
}
