@use 'sass:math';
@use 'sass:map';
@use 'sass:color';

@function create-material-palette($color, $dark-contrast: black, $light-contrast: white) {
  $values: (50, 100, 200, 300, 400, 500, 600, 700, 800, 900, a100, a200, a400, a700);
  $white: white;
  $black: black;
  $base-dark: multiply($color, $color);
  $palette: (
    50: color.mix($color, $white, 12%),
    100: color.mix($color, $white, 30%),
    200: color.mix($color, $white, 50%),
    300: color.mix($color, $white, 70%),
    400: color.mix($color, $white, 85%),
    500: color.mix($color, $white, 100%),
    600: color.mix($color, $base-dark, 87%),
    700: color.mix($color, $base-dark, 70%),
    800: color.mix($color, $base-dark, 54%),
    900: color.mix($color, $base-dark, 25%),
    a100: color.adjust(saturate(color.mix($black, $base-dark, 15%), 80%), $lightness: 65%),
    a200: color.adjust(saturate(color.mix($black, $base-dark, 15%), 80%), $lightness: 55%),
    a400: color.adjust(saturate(color.mix($black, $base-dark, 15%), 100%), $lightness: 45%),
    a700: color.adjust(saturate(color.mix($black, $base-dark, 15%), 100%), $lightness: 40%),
  );
  $contrast: ();

  @each $v in $values {
    $contrast: map.merge(
      $contrast,
      (
        $v: get-material-contrast(map_get($palette, $v), $dark-contrast, $light-contrast),
      )
    );
  }

  $palette: map.merge(
    $palette,
    (
      contrast: $contrast,
    )
  );

  @return $palette;
}

@function multiply($rgb1, $rgb2) {
  $red: math.floor(math.div(color.red($rgb1) * color.red($rgb2), 255));
  $green: math.floor(math.div(color.green($rgb1) * color.green($rgb2), 255));
  $blue: math.floor(math.div(color.blue($rgb1) * color.blue($rgb2), 255));

  @return rgb($red, $green, $blue);
}

@function get-brightness($color) {
  @return math.div((color.red($color) * 299 + color.green($color) * 587 + color.blue($color) * 114), 1000);
}

@function is-light($color) {
  @return get-brightness($color) >= 128;
}

@function get-material-contrast($color, $darkContrast: black, $lightContrast: white) {
  @if is-light($color) {
    @return $darkContrast;
  } @else {
    @return $lightContrast;
  }
}

@function to-rgb($color) {
  @return color.red($color) color.green($color) color.blue($color);
}

@mixin visually-hidden {
  position: absolute;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
  visibility: hidden;
  overflow: hidden;
}

@mixin anchor($top: inherit, $bottom: inherit, $left: inherit, $right: inherit) {
  @include visually-hidden;

  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right;
}

@mixin scrollbar-hidden {
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chromium */
  }
}
