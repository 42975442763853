:root {
  --mdc-outlined-text-field-input-text-placeholder-color: rgb(var(--shadow-purple) / 50%);
  --mdc-outlined-text-field-outline-color: rgb(var(--shadow-purple) / 70%);
  --mdc-outlined-text-field-hover-outline-color: rgb(var(--shadow-purple));
  --mdc-outlined-text-field-label-text-color: rgb(var(--shadow-purple) / 70%);
  --mdc-outlined-text-field-input-text-color: rgb(var(--shadow-purple));
  --mdc-outlined-text-field-disabled-input-text-color: rgb(var(--shadow-purple) / 15%);
  --mdc-outlined-text-field-disabled-label-text-color: rgb(var(--shadow-purple) / 30%);
  --mdc-outlined-text-field-disabled-outline-color: rgb(var(--shadow-purple) / 30%);
  --mat-form-field-disabled-input-text-placeholder-color: rgb(var(--shadow-purple) / 15%);
}

.fbw {
  .mdc-text-field {
    &--outlined {
      --mdc-outlined-text-field-container-shape: 10px;
    }
  }
}
