/* stylelint-disable */

@mixin mobile() {
  @media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin notMobile() {
  @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),
    (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape),
    (min-width: 840px) and (orientation: portrait),
    (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobilePortrait() {
  @media (max-width: 959.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobileLandscape() {
  @media (max-width: 599.98px) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),
    (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin tabletPortrait() {
  @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait) {
    @content;
  }
}

@mixin tabletLandscape() {
  @media (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobileTablet() {
  @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),
    (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape),
    (max-width: 599.98px) and (orientation: portrait),
    (max-width: 959.98px) and (orientation: landscape) {
    @content;
  }
}

@mixin web() {
  @media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
    @content;
  }
}

@mixin webLarge() {
  @media (min-width: 1536px) and (orientation: landscape) {
    @content;
  }
}

@mixin webExtraLarge() {
  @media (min-width: 1920px) and (orientation: landscape) {
    @content;
  }
}
