@use 'utils' as util;
@use 'breakpoints' as breakpoint;
@use 'common/public-header' as header;

// Fonts
$font-text: 'Oxygen', sans-serif;
$font-title: 'Rufina', serif;
$font-display: 'Oleo Script', cursive;

// Section
$mobile-margin-sides: 1.5rem;
$desktop-margin-sides: 8rem;
$desktop-large-margin-sides: 12rem;
$mobile-gap: 3.125rem;
$desktop-gap: 6.25rem;
$section-max-width: 60rem;
$section-large-max-width: 80rem;
$section-title-margin-sides: 2rem;

@mixin section-anchor {
  @include util.anchor($top: calc((var(--section-gap) / -2) - #{header.$height}));

  --section-gap: #{$mobile-gap};

  @include breakpoint.notMobile {
    --section-gap: #{$desktop-gap};
  }
}
