@use 'sass:list';

@use 'sanitize.css/sanitize.css';
@use 'sanitize.css/assets.css';
@use 'sanitize.css/forms.css';
@use 'sanitize.css/reduce-motion.css';
@use 'sanitize.css/typography.css';

@use 'overwrite/material';

@use 'breakpoints' as br;
@use 'colors' as color;
@use 'theming' as theming;
@use 'utils' as util;
@use 'variables' as var;

@include theming.initialize-material;

html {
  font-family: var.$font-text;
  color: rgb(var(--shadow-purple));
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var.$font-title;
  margin: 0;
}

$title-font-sizes: (
  'h1': 2rem 2.5rem,
  'h2': 1.625rem 2rem,
  'h3': 1.25rem 1.625rem,
  'h4': 1.125rem 1.25rem,
);
@each $title-level, $font-sizes in $title-font-sizes {
  #{$title-level} {
    font-size: list.nth($font-sizes, 1);

    @include br.notMobile {
      font-size: list.nth($font-sizes, 2);
    }
  }
}

.visually-hidden {
  @include util.visually-hidden;
}
